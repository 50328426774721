import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import {
	Box,
	Flex,
	VStack,
	Input,
	Button,
	FormControl,
	FormErrorMessage,
	InputRightElement,
	Stack,
} from '@chakra-ui/react'
import { useCheckoutContext } from '../../../../../contexts/CheckoutProvider'
import { useEventContext } from '../../../../../contexts/EventProvider'
import { CancelRounded } from '../../../../atoms/Icon'

const PromoCodeInput = () => {
	const dispatch = useDispatch()
	const {
		location,
		promotion: {
			promotionState: { isValid, data: promo, status, statusMessage },
			fetchPromoCode,
		},
	} = useCheckoutContext()
	const {
		event: { occasion },
	} = useEventContext()

	const [promoCode, setPromoCode] = useState('')
	const phText = 'Have a promo code?'
	const handleSubmit = () => {
		if (promoCode !== '') {
			const data = {
				code: promoCode,
				occasion: occasion,
				countryCode: location?.country ? location.country : undefined,
			}
			fetchPromoCode(data)
		}
	}

	const handleReset = () => {
		dispatch({ type: 'RESET_PROMOTION_STATUS' })
		setPromoCode('')
	}

	useEffect(() => {
		status == 'success' && setPromoCode('')
	}, [status])

	/** Retrieve User's Location finalized on checkout */
	const { country, region } = location

	/**
	 * Logic to determine if the promo code is valid within this country.
	 * If it is, we can update the cart.
	 * */
	const [isPromoCodeNotWithinCountry, setPromoCodeNotWithinCountry] = useState(null)
	// useEffect(() => {
	// 	/** Only check matching if the code is valid */
	// 	if (isValid) {
	// 		/** If we have a promo code but no countryCode assigned */
	// 		if (promo?.code && promo?.countryCode == null) {
	// 			setPromoCodeNotWithinCountry(false)
	// 			/** todo: add code from Cart through api */
	// 			dispatch(updateCart({ ...cartData, promoCode: promo.code }))
	// 		} else if (promo?.code && promo?.countryCode != country) {
	// 			/** If we have a promo code AND a countryCode assigned */
	// 			setPromoCodeNotWithinCountry(true)
	// 			/** todo: remove code from Cart through api */
	// 			dispatch(updateCart({ ...cartData, promoCode: '' }))
	// 		}
	// 	}
	// }, [isValid, promo, country])

	return (
		<VStack w="full">
			<Box w="full">
				<Stack direction="row" justifyContent="space-between" alignItems="start">
					<FormControl isInvalid={status === 'error'}>
						<Input
							size="lg"
							placeholder={phText}
							variant="filled"
							value={promoCode}
							onChange={({ target: { value } }) => setPromoCode(value)}
						/>
						{status === 'error' && (
							<InputRightElement
								h="65%"
								w="10%"
								right="10px"
								cursor="pointer"
								onClick={handleReset}
								children={<CancelRounded />}
							/>
						)}
						<FormErrorMessage>{statusMessage}</FormErrorMessage>
					</FormControl>
					<Flex justify="center">
						<Button variant="ghost" type="submit" onClick={handleSubmit}>
							Apply Code
						</Button>
					</Flex>
				</Stack>
			</Box>
		</VStack>
	)
}

export default PromoCodeInput
