import React, { useEffect, useState } from 'react'
import {
	chakra,
	Box,
	Modal,
	VStack,
	Button,
	Heading,
	Text,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
	SimpleGrid,
	Spinner,
	Image,
	useBreakpointValue,
	List,
	UnorderedList,
	ListItem,
	textDecoration,
	HStack,
} from '@chakra-ui/react'
import { CheckCircleRounded } from '../../atoms/Icon'
import { useCheckoutContext } from '../../../contexts/CheckoutProvider'
import { formatToDecimalAmount } from '../../../../api/checkout/utils'
import loadable from '@loadable/component'
const MembershipPerks = loadable(() => import(/* webpackPrefetch: true */ './membershipPerks.tsx'), {
	ssr: false,
})

export const ModalSubscriptionCheckout = ({ isOpen, onClose, onContinue, onSubscribe }) => {
	const {
		currency,
		productsArray,
		cart,
		cart: { isFetching, data },
		promotion: { removePromoCode },
		cartCalculations: { subTotal, total },
	} = useCheckoutContext()

	const originalPrice = isFetching ? <Spinner /> : formatToDecimalAmount(subTotal, currency?.symbol)
	const memPrice = isFetching ? (
		<Spinner />
	) : data?.totals?.appliedDiscount > data?.upsells?.monthlyMembership?.discountAmount ? (
		formatToDecimalAmount(total, currency?.symbol)
	) : (
		formatToDecimalAmount(subTotal - data?.upsells?.monthlyMembership?.discountAmount, currency?.symbol)
	)

	const couponIsLess = data?.totals?.appliedDiscount < data?.upsells?.monthlyMembership?.discountAmount

	const savings =
		data?.totals?.appliedDiscount > data?.upsells?.yearlyMembership?.discountAmount
			? data?.promotion && data?.promotion?.type == 'percent'
				? `${data?.promotion?.value}%`
				: formatToDecimalAmount(data?.promotion?.value, currency.symbol)
			: `${data?.upsells?.yearlyMembership?.discountPercent}%`

	const [qcPrice, setQcPrice] = useState(900)

	useEffect(() => {
		if (productsArray.length > 0) {
			setQcPrice(productsArray.find((el) => el.metadata.slug === 'qualityCheck').pricing.price)
		}
	}, [productsArray])

	const isCentered = useBreakpointValue({ base: false, md: true })
	const confetti = useBreakpointValue({ base: null, md: 'confetti' })

	return (
		<Modal
			onClose={onClose}
			isOpen={isOpen}
			scrollBehavior="outside"
			variant={confetti}
			size="xl"
			isCentered={isCentered}>
			<ModalOverlay />
			<ModalContent p="2rem 1rem" m="0">
				{/* <ModalCloseButton /> */}
				<ModalBody>
					<MembershipPerks
						// currency={currency}
						currency={{ code: 'USD', symbol: '$' }} // <-- yearly membership is USD only right now
						savings={savings}
						price={{
							full: data?.upsells?.yearlyMembership.yearlyFullPrice,
							discounted: data?.upsells?.yearlyMembership.yearlyDiscountedPrice,
						}}
					/>

					<VStack w="full" spacing="1rem" justifyContent="center">
						{/* <Button
							onClick={() => {
								if (couponIsLess) {
									removePromoCode().then(() => {
										onContinue(true)
									})
								} else {
									onContinue(true)
								}
							}}>
							Become a Monthly Member
						</Button> */}
						<Button onClick={() => onSubscribe()} padding="1.25em">
							Become a Member
						</Button>
						<Button onClick={() => onContinue()} variant="ghost" padding="1.25em">
							Continue as a Non-Member
						</Button>
					</VStack>
				</ModalBody>
			</ModalContent>
		</Modal>
	)
}
