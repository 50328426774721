import React, { useState, useEffect } from 'react'
import { useEventContext } from '../../../contexts/EventProvider'
import { useAuthContext } from '../../../contexts/AuthProvider'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useStripe } from '@stripe/react-stripe-js'
import { Image, Container, Button, HStack, Box, Heading, Text, VStack, useDisclosure } from '@chakra-ui/react'
import { Receipt } from '../../atoms/Card'
import NavBar from '../../atoms/NavBar'
import BackButton from '../../atoms/nav-controls/BackButton'
import SummaryTable from './atoms/SummaryTable'
import FooterBase from '../../molecules/FooterBase'
import { useEventRecipients } from '../../../hooks/useRecipients'
import { useMedia } from '../../../hooks/useMedia'
import { useVideoDurationCalc } from '../../../hooks/useVideoDurationCalc'
import useCartSetup from './hooks/useCartSetup'
import useCheckoutEventSetup from './hooks/useCheckoutEventSetup'
import useIsRepublish from './hooks/useIsRepublish'
import useProductsSetup from './hooks/useProductsSetup'
import getGAClientId from '../../../../utils/getGAClientId'
import { useCheckoutContext } from '../../../contexts/CheckoutProvider'
import { postCartCheckoutSession, postFreeCart } from '../../../../api/checkout/actions'
import LocationManager from './LocationManager'
import Progress from './atoms/Progress'
import useIsWorkingServerSide from './hooks/useIsWorkingServerSide'
import useCountries from '../../../hooks/useCountries'
import FinalizeFooter from './atoms/FinalizeFooter'
import PricingTable from './atoms/PricingTable'
import { ModalSubscriptionCheckout } from '../../organisms/ModalSubscriptionCheckout'
import useProfile from '../../../hooks/useProfile'
import axios from 'axios'
import configClient from '../../../../../client/config.client'
import useDataLayerPush from '../../../hooks/useDataLayerPush'

const Controls = ({ uuid }) => {
	const navigate = useNavigate()
	return (
		<NavBar position="fixed">
			<HStack spacing={['4%', '1rem']}>
				<BackButton left onClick={() => navigate(`/event/${uuid}`)} />
			</HStack>
		</NavBar>
	)
}

const Cart = () => {
	const dispatch = useDispatch()
	const navigate = useNavigate()
	const stripe = useStripe()
	/** Get Authed User */
	useProfile()

	const subscriptionModal = useDisclosure() // used later with subscription model
	const { hasSubscription } = useAuthContext() // used later with subscription model

	/** Retrieve event information */
	const { event } = useEventContext()
	/** Retrieve checkout information */
	const {
		cart: { isFetching, checkoutSession, data: cartData },
		location: { country },
		cartCalculations: { total },
	} = useCheckoutContext()

	const recipients = useEventRecipients()
	const media = useMedia()
	const { durationTime, renderTime } = useVideoDurationCalc(media, event.themeTemplateId, true)
	const [isSubmitting, setIsSubmitting] = useState(false)

	/** Fetch Countries if none */
	useCountries()
	/** Setup cart */
	useCartSetup()
	/** Setup event in the checkout */
	useCheckoutEventSetup()
	/** Check if event is a republish */
	useIsRepublish()
	/** Fetch products when country changes */
	useProductsSetup()

	const isRepublish = cartData?.lineItems[0]?.isRepub
	const submitInfo =
		total > 0
			? `Checkout & ${isRepublish ? 'Republish' : 'Finalize'}`
			: total == 0
			? isRepublish
				? 'Republish Video'
				: 'Finalize Video'
			: ''

	// send user to checkout for annual subscription
	const handleSubscriptionCheckoutSession = async () => {
		// Set redirect urls (only when window is defined)
		if (typeof window !== 'undefined' && window) {
			const cancelUrl = window.location.href // return here if cancelled
			const successUrl = `${window.location.protocol}//${window.location.host}/event/${event.uuid}/checkout/successful` // redirect to success page on success
			const analyticsClientId = getGAClientId()

			try {
				const instance = axios.create({
					withCredentials: true,
					baseURL: configClient.api.baseUrl,
				})
				const response = await instance.post(`/v2/subscriptions/checkout`, {
					successUrl,
					cancelUrl,
					analyticsClientId: analyticsClientId || undefined, // don't include when its null
					interval: 'year',
					metadata: {
						cartId: cartData.id, // used to automatically finalize this cart once the subscription is activated
					},
				})
				const { checkoutSession } = response.data.data

				// Fire off "begin_checkout" event
				useDataLayerPush({ ecommerce: null }) // Important! Clear the previous ecommerce object
				useDataLayerPush({
					event: 'begin_checkout',
					ecommerce: {
						currency: checkoutSession.currency.toUpperCase(),
						value: Number((checkoutSession.amount_total / 100).toFixed(2)),
						coupon: null,
						items: checkoutSession.items.map((item, index) => {
							return {
								index: index,
								item_id: item.price.product, // use the Stripe product id
								coupon: null,
								discount: Number((item.amount_discount / 100).toFixed(2)),
								price: Number((item.amount_total / 100).toFixed(2)), // use price once discount is applied
								quantity: item.quantity,
							}
						}),
					},
				})

				const result = await stripe.redirectToCheckout({ sessionId: checkoutSession.id })
				if (result.error) {
					throw new Error('Stripe error : ' + result.error)
				}
			} catch (err) {
				console.error('Subscription checkout failed! ', err)
			}
		}
	}

	const handleCartCheckoutSession = ({ bundleMonthlyMembership = false }) => {
		// Set redirect urls (only when window is defined)
		let successUrl = null
		let cancelUrl = null
		let analyticsClientId = getGAClientId()
		if (typeof window !== 'undefined' && window) {
			cancelUrl = window.location.href // return here if cancelled
			successUrl = `${window.location.protocol}//${window.location.host}/event/${event.uuid}/checkout/successful` // redirect to success page on success
		}
		setIsSubmitting(true)
		dispatch(
			postCartCheckoutSession({
				successUrl,
				cancelUrl,
				bundleMonthlyMembership,
				analyticsClientId: analyticsClientId || undefined,
			})
		)
	}

	const handleFreeSubmission = () => {
		// Post free cart
		dispatch(postFreeCart())
		setIsSubmitting(true)
		navigate(`/event/${event.uuid}/checkout/successful`)
	}

	// Continue with regular pricing
	const handleContinue = (bundleMonthlyMembership) => {
		// reload window if stripe sdk is not available
		if (total > 0 && !stripe && typeof window !== 'undefined') {
			window.location.reload()
		} else if (total > 0) {
			handleCartCheckoutSession({ bundleMonthlyMembership: bundleMonthlyMembership })
		} else {
			handleFreeSubmission()
		}
	}

	const handleSubmit = (e) => {
		e.preventDefault()
		if (!hasSubscription && !isRepublish && total > 0) {
			subscriptionModal.onOpen() // only show modal for non-subscribers that have payment to make
		} else {
			handleContinue()
		}
	}

	/** Use working/fetching status of the cart */
	const serverSideState = useIsWorkingServerSide()

	/**
	 * Redirect to Stripe Checkout if session url becomes available
	 * */
	useEffect(() => {
		if (checkoutSession) {
			// Fire off "begin_checkout" event
			useDataLayerPush({ ecommerce: null }) // Important! Clear the previous ecommerce object
			useDataLayerPush({
				event: 'begin_checkout',
				ecommerce: {
					currency: checkoutSession.currency.toUpperCase(),
					value: Number((checkoutSession.amount_total / 100).toFixed(2)),
					coupon: checkoutSession.metadata?.promoCode?.length > 0 ? checkoutSession.metadata.promoCode : null,
					items: checkoutSession.items.map((item, index) => {
						return {
							index: index,
							item_id: item.price.product, // use the Stripe product id
							coupon: null,
							discount: Number((item.amount_discount / 100).toFixed(2)),
							price: Number((item.amount_total / 100).toFixed(2)), // use price once discount is applied
							quantity: item.quantity,
						}
					}),
				},
			})

			// Redirect to checkout
			stripe.redirectToCheckout({ sessionId: checkoutSession.id }).then((result) => {
				// Handle when redirect fails
				if (result.error) {
					console.error(result.error.message)
					// TODO Also display some error message in browser if redirect fails...
				}
			})
		}
	}, [checkoutSession])

	return (
		<>
			<Controls uuid={event.uuid} />
			<Progress isAnimating={serverSideState.isAnimating} key={serverSideState.key} />

			<Container maxW="container.sm" py="1rem" variant="main">
				<Box my="2rem" textAlign="center" mx={['1rem', 0]}>
					<Heading as="h2" size="xl" variant="hero">
						Finalize
					</Heading>
				</Box>

				<Receipt p="2rem" position="relative" zIndex="1">
					<Image src="/assets/images/vidday_logo_gray.svg" alt="VidDay" m="0 auto 2rem" />
					<SummaryTable mb="2rem" recipients={recipients} durationTime={durationTime} />
					<VStack w="full" mb=".5rem" justify="center">
						<Button
							variant="solid"
							type="submit"
							disabled={isFetching || isSubmitting || !country}
							onClick={handleSubmit}>
							{submitInfo}
						</Button>
						<Text align="center" size="14px">
							Your video will take about <strong>{renderTime}</strong> to finalize.
						</Text>
					</VStack>
				</Receipt>
				<PricingTable />
				<FinalizeFooter />
				<FooterBase />
			</Container>
			<LocationManager />
			<ModalSubscriptionCheckout
				isOpen={subscriptionModal.isOpen}
				onClose={subscriptionModal.onClose}
				onContinue={handleContinue}
				onSubscribe={handleSubscriptionCheckoutSession}
			/>
		</>
	)
}

const Finalize = () => {
	/** Retrieve event information */
	const { event } = useEventContext()
	const [renderCheckout, setRenderCheckout] = useState(false)
	const navigate = useNavigate()
	useEffect(() => {
		if (event && event.status) {
			if (event.status != 'active') {
				setRenderCheckout(false)
				navigate('/event/' + event.uuid)
			} else {
				setRenderCheckout(true)
			}
		}
	}, [event])

	return renderCheckout && <Cart />
}

export default Finalize
