import React from 'react'
import {
	Box,
	Modal,
	VStack,
	Button,
	Heading,
	Image,
	Text,
	HStack,
	ModalOverlay,
	ModalContent,
	ModalCloseButton,
	ModalBody,
	ModalFooter,
} from '@chakra-ui/react'

export const RushFeeModal = ({ isOpen, onClose }) => {
	return (
		<Modal onClose={onClose} isOpen={isOpen} scrollBehavior="outside" isCentered={true} size="xl">
			<ModalOverlay />
			<ModalContent>
				<ModalCloseButton />
				<ModalBody>
					<VStack textAlign="center" spacing="1rem">
						<Image
							src={'/assets/images/graphics/modals/vidday-rush-graphic.svg'}
							width="137px"
							height="95"
							alt="Rush Fee"
						/>
						<Heading size="lg" variant="hero">
							Need Your Video Faster?
						</Heading>
					</VStack>
					<Box display="grid" textAlign="center">
						<Text mt="0.5rem">Choose this option and your video will be placed in the priority queue.</Text>
						<Text mt="0.5rem">
							<strong>Please Note:</strong>
						</Text>
						<Text mt="0.5rem">
							When you select both <strong>Quality Check and Priority Processing</strong>, we'll
							fast-track your Quality Check, then place your video for priority processing.
						</Text>
						<Text mt="0.5rem">
							If your order is placed overnight, the Quality Check will be prioritized the next morning in
							our timezone (CST).
						</Text>
					</Box>
				</ModalBody>
				<ModalFooter>
					<HStack w="full" spacing="1rem" justifyContent="center">
						<Button onClick={onClose}>Close</Button>
					</HStack>
				</ModalFooter>
			</ModalContent>
		</Modal>
	)
}
