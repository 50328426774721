import React from 'react'
import loadable from '@loadable/component'
import { Spinner, Flex, StackDivider, VStack, Text, Divider, Box, Image, HStack } from '@chakra-ui/react'
import { useCheckoutContext } from '../../../../../contexts/CheckoutProvider'
import { formatToDecimalAmount } from '../../../../../../api/checkout/utils'
import PromoCodeInput from './PromoCodeInput'
import RowThemeCover from './RowThemeCover'
import RowPlantTree from './RowPlantTree'
import RowQualityCheck from './RowQualityCheck'
import RowRushFee from './RowRushFee'
import RowPromoDiscount from './RowPromoDiscount'
import SubscriptionDiscount from './SubscriptionDiscount'
import OccasionDiscount from './OccasionDiscount'
import RepublishDiscount from './RepublishDiscount'
import { useEventContext } from '../../../../../contexts/EventProvider'
import useCoverPhoto from '../../../../../hooks/useCoverPhoto'

const RowVideo = ({ durationTime }) => {
	const {
		cart: { isFetching, videoProduct },
		currency,
	} = useCheckoutContext()

	const coverPhoto = useCoverPhoto()

	const price =
		videoProduct && !isFetching ? (
			<Text>{formatToDecimalAmount(videoProduct.pricing.price, currency.symbol, currency.code)}</Text>
		) : (
			<Spinner as="span" thickness="4px" speed="0.45s" emptyColor="gray.200" color="link" size="md" />
		)

	return (
		<Flex w="full" justifyContent="space-between" alignItems="center">
			<HStack>
				{coverPhoto?.signedPreviewUrl && (
					<Image borderRadius="base" w="48px" h="48px" src={coverPhoto?.signedPreviewUrl} />
				)}
				<Text>
					<strong>1080p HD Video Montage</strong>
					<br />
					{durationTime} in length
				</Text>
			</HStack>
			{price}
		</Flex>
	)
}

const subRowTextStyles = {
	fontWeight: 'bold',
	fontSize: '1.5rem',
}
const RowSubTotal = () => {
	const {
		cartCalculations: { total },
		cart: { isFetching },
		currency,
	} = useCheckoutContext()
	const totalPrice = !isFetching ? (
		<Text sx={subRowTextStyles}>{formatToDecimalAmount(total, currency.symbol, currency.code)}</Text>
	) : (
		<Spinner as="span" thickness="4px" speed="0.45s" emptyColor="gray.200" color="link" size="md" />
	)
	return (
		<Flex w="full" justifyContent="space-between" fontSize="2rem">
			<Text sx={subRowTextStyles}>Subtotal</Text>
			{totalPrice}
		</Flex>
	)
}

const SummaryTable = ({ recipients, durationTime, ...rest }) => {
	const {
		event: { isRepublish, isFree, hasSubscription },
		promotion: {
			promotionState: { isValid, data: promo, dataServer: serverPromo },
		},
	} = useCheckoutContext()
	const { themeCover } = useEventContext()

	return (
		<>
			<VStack
				w="full"
				divider={<StackDivider borderStyle="dashed" borderColor="#D0D6DD" />}
				spacing="1rem"
				{...rest}>
				{/* {!isRepublish && !isFree && !hasSubscription && !((promo && isValid) || serverPromo) && ( */}
				<PromoCodeInput />
				{/* )} */}

				<RowVideo durationTime={durationTime} />
				{/** Only show custom theme cover line item when used */}
				{!isRepublish && themeCover !== null && <RowThemeCover />}
				{/** Only show plant tree option on first publish */}
				{!isRepublish && <RowRushFee />}
				{/** Only show the quality check option on first publish */}
				{!isRepublish && <RowQualityCheck />}
				{/** Only show plant tree option on first publish */}
				{!isRepublish && <RowPlantTree />}
				{/** Display subscription (B2B) discount when applicable */}
				{hasSubscription && <SubscriptionDiscount />}
				{/** Display free occasion discount when applicable */}
				{isFree && !hasSubscription && <OccasionDiscount />}
				{/** Display republish discount when applicable */}
				{isRepublish && !isFree && !hasSubscription && <RepublishDiscount />}
				{((promo && isValid) || serverPromo) && <RowPromoDiscount />}
				<RowSubTotal />
			</VStack>
		</>
	)
}

export default SummaryTable
